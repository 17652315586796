export const checkArea2 = (zipCode: number) => {
  return zipCode >= 63000 && zipCode <= 63644;
};

const deliverySection = [
  22386, 22388, 23004, 23005, 23006, 23007, 23008, 23009, 23010, 23100, 23101,
  23102, 23103, 23104, 23105, 23106, 23107, 23108, 23110, 23111, 23112, 23113,
  23114, 23115, 23116, 23124, 23125, 23126, 23127, 23128, 23129, 23130, 23131,
  23132, 23133, 23134, 23135, 23136, 31708, 32133, 33411, 40200, 40201, 40202,
  40203, 40204, 40205, 40206, 40207, 40208, 40209, 40210, 40211, 40212, 40213,
  40214, 40215, 40216, 40217, 40218, 40219, 40220, 40221, 40222, 40223, 40224,
  40225, 40226, 40227, 40228, 40229, 40230, 40231, 40232, 40233, 40234, 40235,
  40236, 40237, 40238, 40239, 40240, 52570, 52571, 53031, 53032, 53033, 53089,
  53090, 53091, 53092, 53093, 53094, 53095, 53096, 53097, 53098, 53099, 53100,
  53101, 53102, 53103, 53104, 54000, 46768, 46769, 46770, 46771, 56347, 56348,
  56349, 57068, 57069, 58760, 58761, 58762, 58800, 58801, 58802, 58803, 58804,
  58805, 58806, 58807, 58808, 58809, 58810, 58816, 58817, 58818, 58826, 58828,
  58829, 58830, 58831, 58832, 58833, 58834, 58835, 58836, 58837, 58838, 58839,
  58840, 58841, 58842, 58843, 58844, 58845, 58846, 58847, 58848, 58849, 58850,
  58851, 58852, 58853, 58854, 58855, 58856, 58857, 58858, 58859, 58860, 58861,
  58862, 58863, 58864, 58865, 58866, 58953, 58954, 58955, 58956, 58957, 58958,
  59102, 59103, 59106, 59127, 59129, 59137, 59138, 59139, 59140, 59141, 59142,
  59143, 59144, 59145, 59146, 59147, 59148, 59149, 59150, 59151, 59152, 59153,
  59154, 59155, 59156, 59157, 59158, 59159, 59160, 59161, 59162, 59163, 59164,
  59165, 59166, 59421, 59531, 59551, 59563, 59568, 59650, 59766, 59781, 59782,
  59783, 59784, 59785, 59786, 59787, 59788, 59789, 59790, 63000, 63001, 63365,
];

export const checkArea3 = (zipCode: number) => {
  return deliverySection.includes(zipCode);
};
